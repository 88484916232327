
import { Vue, Component } from "vue-property-decorator";
import { Certificates } from "@/api";

@Component({
  components: {}
})
export default class DownloadCertificatePage extends Vue {
  isLoading = true;
  isDownloadSuccessful = true;
  async created() {
    await this.loadPdf();
  }
  loadPdf() {
    const { id } = this.$route.params;
    Certificates.DownloadCertificate(id)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const certificateId = "certificate.zip";

        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", certificateId);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(e => {
        this.isDownloadSuccessful = false;
        this.$error(e.errors);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
